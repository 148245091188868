import React from "react";

const UserListRow = ({
  data,
  onView,
  onEdit,
  onDelete,
  isLoading,
  handleStatusChange,
  isLoadingUserStatus,
}) => {
  return (
    <>
      {data?.map((item, index) => {
        return (
          <tr
            className="cursor-pointer"
            onClick={() => onView(item)}
            key={index}
          >
            <td className="align-middle px-4">
              {item?.first_name || item?.last_name ? (
                <span className="text-secondary text-sm font-weight-bold text-center">
                  {`${item?.first_name} ${item?.last_name}`}
                </span>
              ) : (
                <span className="text-secondary text-sm font-weight-bold text-center">
                  N/A
                </span>
              )}
            </td>
            <td className="align-middle px-4 ">
              <span className="text-secondary text-sm font-weight-bold text-center">
                {item.email || "N/A"}
              </span>
            </td>
            <td className="align-middle px-4 ">
              {item.country_code && item.phone_number ? (
                <span className="text-secondary text-sm font-weight-bold text-center">
                  {`+${item.country_code} ${item.phone_number}`}
                </span>
              ) : (
                <span className="text-secondary text-sm font-weight-bold text-center">
                  N/A
                </span>
              )}
            </td>
            <td className="align-middle px-4 ">
              <span
                style={{ width: "100px" }}
                class={
                  item?.status === 1
                    ? "badge badge-sm bg-gradient-success cursor-pointer"
                    : "bg-gradient-secondary badge badge-sm cursor-pointer"
                }
              >
                {item?.status === 1 ? "Active" : "Suspended"}
              </span>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default UserListRow;
