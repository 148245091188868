/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import ImagesUrl from "../../utils/constants/images";
import ScreenLayout from "../../components/UI/screenLayout";
import usePost from "../../hooks/usePost";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import { toast } from "react-toastify";
import TempLayout from "../../components/UI/tempLayout";
import { uniqueIdGenerator } from "../../utils/helpers";
import { RoutePath } from "../../routes/routePath";
import { useNavigate } from "react-router-dom";

const RealityCheck = ({ props }) => {
  const {
    data,
    templateId,
    handleEditForm,
    editTemplate,
    refetchStepDetail,
    dataLoading,
    stepId,
  } = props;

  const [form1, setForm1] = useState({
    formId: uniqueIdGenerator(),
    formType: "simple continue",
    description:
      "Challenge your perceptions, explore gendered norms, and maybe even discover something new.",
    buttonText: "Tap To Continue",
  });
  const [form2, setForm2] = useState({
    formId: uniqueIdGenerator(),
    formType: "simple continue",
    description: "Dive in and see how well reality aligns with our beliefs.",
    buttonText: "Tap To Continue",
  });
  const [form3, setForm3] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",
    description:
      "Read each statement and decide whether you believe it to be true or false",
    buttonText: "Let's Go",
  });
  const [finalForm, setFinalForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "final",
    image: {
      form_unique_id: "",
      added_image: "",
    },
    description: "Congratulations on completing the Reality Check!",
    returnHomeButtonText: "Return to Home",
    buttonText: "Continue Learning",
  });

  const [subForm, setSubForm] = useState([
    [
      {
        formId: uniqueIdGenerator(),
        formType: "action",
        title: "Statement #1",
        description:
          "Read each statement and decide whether you believe it to be true or false",
        agreeButtonText: "False",
        disagreeButtonText: "True",
        answer: "true",
      },
      {
        formId: uniqueIdGenerator(),
        formType: "continue",
        title: "Finding #1",
        description:
          "One study found that up to 79% of women regularly lack confidence when speaking up at work and in their careers generally, compared to 62% of men.",
        info: "based on Brien (2019)",
        infoLink: "http://www.google.com",
        buttonText: "Tap to continue",
      },
    ],
  ]);

  const [realtyCheckData, setRealtyCheckData] = useState([
    {
      name: data?.name ?? "Reality Check",
      form_json: [
        form1,
        form2,
        form3,
        {
          subForms: subForm,
        },
        finalForm,
      ],
    },
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const nav = useNavigate();
  const fileInput = useRef(null);
  const { mutateAsync: addImagesMutateAsync, isLoading: isLoadingAddImages } =
    usePost(ENDPOINTS.TEMPLATE_IMAGES, QUERY_KEYS.TEMPLATE_IMAGES_QUERY_KEY);
  const {
    mutateAsync: addTemplateMutateAsync,
    isLoading: isLoadingAddTemplate,
  } = usePost(
    ENDPOINTS.TEMPLATE_DATA_ADD,
    QUERY_KEYS.TEMPLATE_DATA_ADD_QUERY_KEY
  );

  const uploadFormJson = async (formData) => {
    const params = {
      step_id: stepId,
      sub_step_id: data?._id,
      template_id: templateId,
      template_json: formData,
    };

    try {
      const response = await addTemplateMutateAsync(params);

      if (response.statusCode === 200) {
        refetchStepDetail();
        toast.success(response?.message);
        handleEditForm();
        nav(RoutePath.SKILL_DETAIL.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    }
    //add upload form api
  };

  const handelApply = async (imageResponse) => {
    const formData = [
      {
        name: data?.name ?? "Reality Check",
        form_json: [
          form1,
          form2,
          form3,
          {
            subForms: subForm,
          },
          {
            ...finalForm,
            image: imageResponse?.data ?? finalForm.image,
          },
        ],
      },
    ];
    setRealtyCheckData(formData);
    if (imageResponse !== null) {
      setFinalForm({
        ...finalForm,
        image: imageResponse?.data,
      });
    }

    uploadFormJson(formData);
  };

  const handleUpload = async (callback) => {
    if (selectedFile == null) {
      callback(null);
    } else {
      const imageId = finalForm?.image?.form_unique_id ?? "";
      const formData = new FormData();
      formData.append("step_id", stepId);
      formData.append("sub_step_id", data?._id);
      formData.append("template_id", templateId);
      formData.append("form_unique_id", imageId);
      formData.append("image", selectedFile);
      try {
        const response = await addImagesMutateAsync(formData);

        if (response.statusCode === 200) {
          callback(response);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  const addNewStatement = () => {
    const length = subForm.length + 1;
    setSubForm([
      ...subForm,
      [
        {
          formId: uniqueIdGenerator(),
          formType: "action",
          title: `Statement #${length}`,
          description:
            "Read each statement and decide whether you believe it to be true or false",
          agreeButtonText: "False",
          disagreeButtonText: "True",
          answer: "true",
        },
        {
          formId: uniqueIdGenerator(),
          formType: "continue",
          title: `Finding #${length}`,
          description:
            "One study found that up to 79% of women regularly lack confidence when speaking up at work and in their careers generally, compared to 62% of men.",
          info: "based onO’Brien (2019)",
          infoLink: "http://www.google.com",
          buttonText: "Tap to continue",
        },
      ],
    ]);
  };

  const handleFileInput = (target) => {
    setSelectedFile(target.files[0]);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const removeStatement = (index) => {
    const newSubForm = [...subForm];
    newSubForm.splice(index, 1);
    setSubForm(newSubForm);
  };

  const handleSubForm = (event, index, subIndex, key) => {
    setSubForm((prevSubForm) => {
      prevSubForm[index][subIndex][key] = event.target.innerText;
      return [...prevSubForm];
    });
  };
  const handleSubFormAns = (event, index, subIndex, key) => {
    setSubForm((prevSubForm) => {
      prevSubForm[index][subIndex][key] = event.target.value;
      return [...prevSubForm];
    });
  };

  useEffect(() => {
    if (data?.template_id == 1) {
      const forms = data?.form_json;
      if (forms && forms?.length !== 0) {
        setRealtyCheckData(forms);
        setForm1(forms[0]?.form_json[0]);
        setForm2(forms[0]?.form_json[1]);
        setForm3(forms[0]?.form_json[2]);
        setSubForm(forms[0]?.form_json[3]?.subForms);
        setFinalForm(forms[0]?.form_json[4]);
      } else {
        handleEditForm(true);
      }
    }

    // setSelectedFile();
  }, [, refetchStepDetail]);

  return (
    <TempLayout
      handleEditForm={handleEditForm}
      editTemplate={editTemplate}
      handleApply={() => handleUpload(handelApply)}
      loading={isLoadingAddTemplate || isLoadingAddImages}
      dataLoading={dataLoading}
    >
      <section className="screen-section">
        <div className="container-fluid gx-0">
          <div className="row gap-3">
            <div className="col-12">
              <div className="screen-wrapper">
                <ScreenLayout name={data?.name}>
                  <div className="screen-body">
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "377px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm1({
                                ...form1,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form1.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm1({
                            ...form1,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form1.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data?.name}>
                  <div className="screen-body">
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "377px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm2({
                                ...form2,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form2?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm2({
                            ...form2,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form2?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data?.name}>
                  <div className="screen-body">
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "377px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm3({
                                ...form3,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form3?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm3({
                            ...form3,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form3?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                {subForm.map((item, index) => {
                  return (
                    <div key={index} className="position-relative">
                      {index > 0 && editTemplate ? (
                        <i
                          className="far fa-trash-alt text-danger border rounded-2 border-warning  p-2 position-absolute  cursor-pointer"
                          style={{
                            top: "10px",
                            right: "20px",
                          }}
                          onClick={() => removeStatement(index)}
                          aria-hidden="true"
                        ></i>
                      ) : null}

                      <div
                        className={
                          index > 0 && editTemplate
                            ? "d-flex border border-success p-1"
                            : "d-flex "
                        }
                        style={{ gap: "20px", borderRadius: "20px" }}
                      >
                        <ScreenLayout name={data?.name}>
                          <div className="screen-body px-50">
                            <div className="hastags-content">
                              <div
                                className="checkbox-wrapper"
                                style={{ maxHeight: "48px" }}
                              >
                                <div className="checkbox-list">
                                  <p
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handleSubForm(e, index, 0, "title")
                                    }
                                  >
                                    {item[0]?.title}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="screen-content">
                              <div
                                className="checkbox-wrapper"
                                style={{ maxHeight: "278px", marginTop: "0px" }}
                              >
                                <div className="checkbox-list">
                                  <p
                                    className="text-start"
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handleSubForm(e, index, 0, "description")
                                    }
                                  >
                                    {item[0]?.description}
                                  </p>
                                </div>
                                <div>
                                  Select Answer
                                  <div className="checkbox-list">
                                    <div className="d-flex gap-4 mt-2">
                                      <input
                                        type="radio"
                                        className="d-none"
                                        name={`checkbox_3${index}`}
                                        id={`checkbox_3_true${index}`}
                                        value="true"
                                        onChange={(e) =>
                                          handleSubFormAns(
                                            e,
                                            index,
                                            0,
                                            "answer"
                                          )
                                        }
                                        checked={item[0]?.answer === "true"}
                                      />
                                      <label
                                        htmlFor={`checkbox_3_true${index}`}
                                      >
                                        <span className="check-box-icon" />
                                        <span className="check-box-text">
                                          True
                                        </span>
                                      </label>

                                      <input
                                        type="radio"
                                        className="d-none"
                                        name={`checkbox_3${index}`}
                                        id={`checkbox_3_false${index}`}
                                        value="false"
                                        onChange={(e) =>
                                          handleSubFormAns(
                                            e,
                                            index,
                                            0,
                                            "answer"
                                          )
                                        }
                                        checked={item[0]?.answer === "false"}
                                      />
                                      <label
                                        htmlFor={`checkbox_3_false${index}`}
                                      >
                                        <span className="check-box-icon" />
                                        <span className="check-box-text">
                                          False
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="screen-footer">
                            <div className="footer-nav-btn">
                              <div
                                className="btn-style btn-disagree-style"
                                contentEditable={editTemplate}
                                onBlur={(e) =>
                                  handleSubForm(
                                    e,
                                    index,
                                    0,
                                    "disagreeButtonText"
                                  )
                                }
                              >
                                {item[0]?.disagreeButtonText}
                              </div>
                              <div
                                className="btn-style btn-agree-style"
                                contentEditable={editTemplate}
                                onBlur={(e) =>
                                  handleSubForm(e, index, 0, "agreeButtonText")
                                }
                              >
                                {item[0]?.agreeButtonText}
                              </div>
                            </div>
                          </div>
                        </ScreenLayout>
                        <div className="screen-layout">
                          <div className="screen-indicator">
                            <span className="time-indicator">9:41</span>
                            {index < 1 || !editTemplate ? (
                              <span className="mobile-indicator">
                                <img
                                  src={ImagesUrl.mobile_Indicator}
                                  alt="Indicator"
                                />
                              </span>
                            ) : null}
                          </div>
                          <div className="screen-header">
                            <span className="back-btn">
                              <img src={ImagesUrl.back_arrow} alt="Arrow" />
                            </span>
                            <h2>{data?.name}</h2>
                          </div>
                          <div className="screen-body px-50">
                            <div
                              className="checkbox-wrapper"
                              style={{ maxHeight: "377px", marginTop: "0px" }}
                            >
                              <div
                                className="checkbox-list"
                                style={{ maxWidth: "230px" }}
                              >
                                <div className="hastags-content">
                                  <p
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handleSubForm(e, index, 1, "title")
                                    }
                                  >
                                    {item[1]?.title}
                                  </p>
                                </div>
                                <div className="screen-content">
                                  <p
                                    className="text-start"
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handleSubForm(e, index, 1, "description")
                                    }
                                  >
                                    {item[1]?.description}
                                  </p>
                                </div>
                                <div className="link-blk">
                                  <p
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handleSubForm(e, index, 1, "info")
                                    }
                                  >
                                    {item[1]?.info}
                                  </p>
                                </div>
                                <div className="link-blk">
                                  <p
                                    className=""
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handleSubForm(e, index, 1, "infoLink")
                                    }
                                  >
                                    <a>{item[1]?.infoLink}</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="screen-footer">
                            <div className="footer-nav-btn">
                              <div
                                className="btn-text-style"
                                contentEditable={editTemplate}
                                onBlur={(e) =>
                                  handleSubForm(e, index, 1, "buttonText")
                                }
                              >
                                {item[1]?.buttonText}
                              </div>
                            </div>
                            <div className="footer-bar-blk">
                              <span className="footer-bar" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {editTemplate ? (
                  <div className="screen-layout border border-success">
                    <div className="d-flex justify-content-center align-items-center flex-column h-100">
                      <h3
                        className="cursor-pointer"
                        onClick={editTemplate ? addNewStatement : null}
                      >
                        <i class="fa-solid fa-square-plus"></i> Statement
                      </h3>
                    </div>
                  </div>
                ) : null}

                <ScreenLayout name={data?.name} notHeaderRequire="true">
                  <div className="screen-body px-35">
                    <div className="upload-img-blk">
                      <img
                        className="preview-img"
                        src={
                          selectedFile === null
                            ? finalForm?.image?.added_image === ""
                              ? ImagesUrl.shape_image
                              : `https://leadherself-api.csdevhub.com/src/public/form_images/${finalForm?.image?.added_image}`
                            : URL.createObjectURL(selectedFile)
                        }
                        alt="Image"
                      />
                      <div className="upload-img-file">
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          ref={fileInput}
                          onChange={({ target }) => handleFileInput(target)}
                        />
                        <label
                          htmlFor="ImageUpload"
                          onClick={
                            editTemplate ? handleSelectUploadImage : null
                          }
                        >
                          <i className="fa-solid fa-image" />
                        </label>
                      </div>
                    </div>
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "260px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            className="ls-font ft-22 lht-32"
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div
                      className="btn-text-style py-0"
                      contentEditable={editTemplate}
                      onBlur={(e) => {
                        setFinalForm({
                          ...finalForm,
                          returnHomeButtonText: e.target.innerText,
                        });
                      }}
                    >
                      {finalForm.returnHomeButtonText}
                    </div>
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setFinalForm({
                            ...finalForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {finalForm.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TempLayout>
  );
};

export default RealityCheck;
