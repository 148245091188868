import * as Yup from "yup";

const addExerciseInitialValues = {
  exerciseTitle: "",
  exerciseTime: "",
  exerciseImage: "",
  exercisePoints: "",
};
const addExerciseValidationSchema = Yup.object().shape({
  exerciseTitle: Yup.string().trim().required("Title is required*"),
  exercisePoints: Yup.string().trim().required("Points is required*"),
  exerciseTime: Yup.string().trim().required("Time estimate is required*"),
  exerciseImage: Yup.string().trim().required("Image is required*"),
});

export { addExerciseInitialValues, addExerciseValidationSchema };
