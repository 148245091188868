/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import TempLayout from "../../components/UI/tempLayout";
import ScreenLayout from "../../components/UI/screenLayout";
import ImagesUrl from "../../utils/constants/images";
import ScenarioOptions from "../../components/UI/scenarioOptions";
import usePost from "../../hooks/usePost";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import { toast } from "react-toastify";
import { uniqueIdGenerator } from "../../utils/helpers";
import { RoutePath } from "../../routes/routePath";
import { useNavigate } from "react-router-dom";

const Scenario = ({ props }) => {
  const {
    data,
    templateId,
    handleEditForm,
    editTemplate,
    refetchStepDetail,
    dataLoading,
    stepId,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInput = useRef(null);
  const [form1, setForm1] = useState({
    formId: uniqueIdGenerator(),
    formType: "simple continue",
    description:
      "Evaluate how you respond to a common work situation that tests your skills.",
    buttonText: "Tap To Continue",
  });
  const [form2, setForm2] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",
    description:
      "Read the scenario and choose the approach that you would most likely take in response.",
    buttonText: "Let's Go",
  });

  const [partSubForm, setPartSubForm] = useState([
    {
      formId: uniqueIdGenerator(),
      formType: "part continue",
      title: "Imagine this scenario",
      part: "Part 1",
      description:
        "Despite being one of the top performers in sales consistently over the past years, you know that you are paid a bit less then most of your colleagues.",
      buttonText: "Tap to continue",
    },
  ]);

  const [questionSubform, setQuestionSubform] = useState([
    {
      formId: uniqueIdGenerator(),
      formType: "Question",
      question: "What would you do?",
      options: [
        {
          value:
            "Wait a few more months then bring up the issue. They might be planning to give you a raise and you don't want to seem demanding.",
          feedback:
            "Definitely do your research but you’ve covered this already and you may fall into overthinking and procrastinating. Not to mention, looking for reasons to put yourself down will only hold you back.",
        },
        {
          value:
            "Talk to a colleague who you trust and knows you well to see if they feel you are due a raise.",
          feedback:
            "Definitely do your research but you’ve covered this already and you may fall into overthinking and procrastinating. Not to mention, looking for reasons to put yourself down will only hold you back.",
        },
        {
          value:
            "Launch a more thorough investigation into the job market, seeking out any lower paying jobs similar to yours in case there is justification for your current level.",
          feedback:
            "Definitely do your research but you’ve covered this already and you may fall into overthinking and procrastinating. Not to mention, looking for reasons to put yourself down will only hold you back.",
        },
        {
          value:
            "Email your manager about meeting to discuss a raise, include a brief outline of your contributions, relevant market research for similar jobs, and a pay range you feel is suitable.",
          feedback:
            "Definitely do your research but you’ve covered this already and you may fall into overthinking and procrastinating. Not to mention, looking for reasons to put yourself down will only hold you back.",
        },
      ],
      answer: 1,
      buttonText: "Submit option",
    },
  ]);
  const [finalForm, setFinalForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "final",
    image: {
      form_unique_id: "",
      added_image: "",
    },
    description: "Congratulations on completing the  Scenario!",
    returnHomeButtonText: "Return to Home",
    buttonText: "Continue Learning",
  });
  const [scenarioCheckData, setScenarioData] = useState([
    {
      name: data?.name ?? "Reality Check",
      form_json: [
        form1,
        form2,
        {
          subForms: partSubForm,
        },
        {
          subForms: questionSubform,
        },
        finalForm,
      ],
    },
  ]);
  const nav = useNavigate();
  const { mutateAsync: addImagesMutateAsync, isLoading: isLoadingAddImages } =
    usePost(ENDPOINTS.TEMPLATE_IMAGES, QUERY_KEYS.TEMPLATE_IMAGES_QUERY_KEY);
  const {
    mutateAsync: addTemplateMutateAsync,
    isLoading: isLoadingAddTemplate,
  } = usePost(
    ENDPOINTS.TEMPLATE_DATA_ADD,
    QUERY_KEYS.TEMPLATE_DATA_ADD_QUERY_KEY
  );

  const addNewPartForm = () => {
    const length = partSubForm.length + 1;
    setPartSubForm([
      ...partSubForm,

      {
        formId: uniqueIdGenerator(),
        formType: "part continue",
        title: "Imagine this scenario",
        part: `Part ${length}`,
        description:
          "Despite being one of the top performers in sales consistently over the past years, you know that you are paid a bit less then most of your colleagues.",
        buttonText: "Tap to continue",
      },
    ]);
  };
  const addNewQuestionForm = () => {
    setQuestionSubform([
      ...questionSubform,

      {
        formId: uniqueIdGenerator(),
        formType: "Question",
        question: "What would you do?",
        options: [
          {
            value:
              "Wait a few more months then bring up the issue. They might be planning to give you a raise and you don't want to seem demanding.",
            feedback:
              "Definitely do your research but you’ve covered this already and you may fall into overthinking and procrastinating. Not to mention, looking for reasons to put yourself down will only hold you back.",
          },
          {
            value:
              "Talk to a colleague who you trust and knows you well to see if they feel you are due a raise.",
            feedback:
              "Definitely do your research but you’ve covered this already and you may fall into overthinking and procrastinating. Not to mention, looking for reasons to put yourself down will only hold you back.",
          },
          {
            value:
              "Launch a more thorough investigation into the job market, seeking out any lower paying jobs similar to yours in case there is justification for your current level.",
            feedback:
              "Definitely do your research but you’ve covered this already and you may fall into overthinking and procrastinating. Not to mention, looking for reasons to put yourself down will only hold you back.",
          },
          {
            value:
              "Email your manager about meeting to discuss a raise, include a brief outline of your contributions, relevant market research for similar jobs, and a pay range you feel is suitable.",
            feedback:
              "Definitely do your research but you’ve covered this already and you may fall into overthinking and procrastinating. Not to mention, looking for reasons to put yourself down will only hold you back.",
          },
        ],
        answer: 1,
        buttonText: "Submit option",
      },
    ]);
  };
  const removePartForms = (index) => {
    const newSubForm = [...partSubForm];
    newSubForm.splice(index, 1);
    setPartSubForm(newSubForm);
  };
  const removeQuestionForms = (index) => {
    const newSubForm = [...questionSubform];
    newSubForm.splice(index, 1);
    setQuestionSubform(newSubForm);
  };
  const handlePartForm = (event, index, key) => {
    setPartSubForm((prevSubForm) => {
      prevSubForm[index][key] = event.target.innerText;
      return [...prevSubForm];
    });
  };
  const updateQuestion = (event, index, key) => {
    setQuestionSubform((prevQuestionSubform) => {
      const newQuestionSubform = [...prevQuestionSubform];
      newQuestionSubform[index][key] = event.target.innerText;
      return newQuestionSubform;
    });
  };
  const handleQuestionForm = (event, index, key, subIndex, subKey) => {
    setQuestionSubform((prevSubForm) => {
      prevSubForm[index][key][subIndex][subKey] = event.target.innerText;
      return [...prevSubForm];
    });
  };
  const handleQuestionAns = (event, index, key) => {
    setQuestionSubform((prevSubForm) => {
      prevSubForm[index][key] = event.target.value;
      return [...prevSubForm];
    });
  };
  const handleFileInput = (target) => {
    setSelectedFile(target.files[0]);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handelApply = (imageResponse) => {
    const formData = [
      {
        name: data?.name ?? "Scenario",
        form_json: [
          form1,
          form2,
          {
            subForms: partSubForm,
          },
          {
            subForms: questionSubform,
          },
          {
            ...finalForm,
            image: imageResponse?.data ?? finalForm.image,
          },
        ],
      },
    ];
    setScenarioData(formData);
    if (imageResponse !== null) {
      setFinalForm({
        ...finalForm,
        image: imageResponse?.data,
      });
    }
    uploadFormJson(formData);
  };
  const uploadFormJson = async (formData) => {
    const params = {
      step_id: stepId,
      sub_step_id: data?._id,
      template_id: templateId,
      template_json: formData,
    };

    try {
      const response = await addTemplateMutateAsync(params);

      if (response.statusCode === 200) {
        refetchStepDetail();
        toast.success(response?.message);
        handleEditForm();
        nav(RoutePath.SKILL_DETAIL.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    }
    //add upload form api
  };
  const handleUpload = async (callback) => {
    if (selectedFile == null) {
      callback(null);
    } else {
      const imageId = finalForm?.image?.form_unique_id ?? "";
      const formData = new FormData();
      formData.append("step_id", stepId);
      formData.append("sub_step_id", data?._id);
      formData.append("template_id", templateId);
      formData.append("form_unique_id", imageId);
      formData.append("image", selectedFile);
      try {
        const response = await addImagesMutateAsync(formData);

        if (response.statusCode === 200) {
          callback(response);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };
  useEffect(() => {
    if (data?.template_id == 3) {
      const forms = data?.form_json;
      if (forms && forms?.length !== 0) {
        setForm1(forms[0]?.form_json[0]);
        setForm2(forms[0]?.form_json[1]);
        setPartSubForm(forms[0]?.form_json[2]?.subForms);
        setQuestionSubform(forms[0]?.form_json[3]?.subForms);
        setFinalForm(forms[0]?.form_json[4]);
      } else {
        handleEditForm(true);
      }
    }
  }, [, refetchStepDetail]);
  return (
    <TempLayout
      handleEditForm={handleEditForm}
      editTemplate={editTemplate}
      handleApply={() => handleUpload(handelApply)}
      loading={isLoadingAddTemplate || isLoadingAddImages}
      dataLoading={dataLoading}
    >
      <section className="screen-section">
        <div className="container-fluid gx-0">
          <div className="row gap-3">
            <div className="col-12">
              <div className="screen-wrapper">
                <ScreenLayout name={data.name}>
                  <div className="screen-body">
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "380px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm1({
                                ...form1,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form1.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm1({
                            ...form1,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form1.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data.name}>
                  <div className="screen-body">
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "395px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm2({
                                ...form2,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form2.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm2({
                            ...form2,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form2.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                {partSubForm?.map((item, index) => {
                  return (
                    <div key={index} className="position-relative">
                      {index > 0 && editTemplate ? (
                        <i
                          className="far fa-trash-alt text-danger border rounded-2 border-warning  p-2 position-absolute  cursor-pointer"
                          style={{
                            top: "10px",
                            right: "20px",
                          }}
                          onClick={() => removePartForms(index)}
                          aria-hidden="true"
                        ></i>
                      ) : null}

                      <div
                        className={
                          index > 0 && editTemplate
                            ? "d-flex border border-success p-1"
                            : "d-flex "
                        }
                        style={{ gap: "20px", borderRadius: "20px" }}
                      >
                        <div className="screen-layout">
                          <div className="screen-indicator">
                            <span className="time-indicator">9:41</span>
                            {index < 1 || !editTemplate ? (
                              <span className="mobile-indicator">
                                <img
                                  src={ImagesUrl?.mobile_Indicator}
                                  alt="Indicator"
                                />
                              </span>
                            ) : null}
                          </div>
                          <div className="screen-header">
                            <span className="back-btn">
                              <img src={ImagesUrl.back_arrow} alt="Arrow" />
                            </span>
                          </div>
                          <div
                            className="checkbox-wrapper"
                            style={{ minHeight: "420px", marginTop: "0px" }}
                          >
                            <div className="checkbox-list">
                              <div className="screen-body px-50">
                                <div className="bold-font-style">
                                  <p
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handlePartForm(e, index, "title")
                                    }
                                  >
                                    {item?.title}
                                  </p>
                                </div>
                                <div className="hastags-content">
                                  <p
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handlePartForm(e, index, "part")
                                    }
                                  >
                                    {item?.part}
                                  </p>
                                </div>
                                <div className="screen-content">
                                  <p
                                    className="text-start"
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handlePartForm(e, index, "description")
                                    }
                                  >
                                    {item?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="screen-footer">
                            <div className="footer-nav-btn">
                              <div
                                className="btn-text-style"
                                contentEditable={editTemplate}
                                onBlur={(e) =>
                                  handlePartForm(e, index, "buttonText")
                                }
                              >
                                {item?.buttonText}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {editTemplate ? (
                  <div className="screen-layout border border-success">
                    <div className="d-flex justify-content-center align-items-center flex-column h-100">
                      <h3
                        className="cursor-pointer"
                        onClick={editTemplate ? addNewPartForm : null}
                      >
                        <i class="fa-solid fa-square-plus"></i> Scenario
                      </h3>
                    </div>
                  </div>
                ) : null}

                {questionSubform?.map((item, index) => {
                  return (
                    <div key={index} className="position-relative">
                      {index > 0 && editTemplate ? (
                        <i
                          className="far fa-trash-alt text-danger border rounded-2 border-warning  p-2 position-absolute  cursor-pointer"
                          style={{
                            top: "10px",
                            right: "20px",
                          }}
                          onClick={() => removeQuestionForms(index)}
                          aria-hidden="true"
                        ></i>
                      ) : null}

                      <div
                        className={
                          index > 0 && editTemplate
                            ? "d-flex border border-success p-1"
                            : "d-flex "
                        }
                        style={{ gap: "20px", borderRadius: "20px" }}
                      >
                        <div className="screen-layout">
                          <div className="screen-indicator">
                            <span className="time-indicator">9:41</span>
                            {index < 1 || !editTemplate ? (
                              <span className="mobile-indicator">
                                <img
                                  src={ImagesUrl?.mobile_Indicator}
                                  alt="Indicator"
                                />
                              </span>
                            ) : null}
                          </div>
                          <div className="screen-header">
                            <span className="back-btn">
                              <img src={ImagesUrl.back_arrow} alt="Arrow" />
                            </span>
                          </div>
                          <div
                            className="checkbox-wrapper"
                            style={{ minHeight: "420px", marginTop: "0px" }}
                          >
                            <div className="checkbox-list">
                              <div className="screen-body px-35">
                                <div className="bold-font-style">
                                  <p
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      updateQuestion(e, index, "question")
                                    }
                                  >
                                    {item?.question}
                                  </p>
                                </div>
                                <div className="checkbox-wrapper">
                                  {item?.options?.map((data, i) => {
                                    return (
                                      <ScenarioOptions
                                        i={i}
                                        data={data}
                                        editTemplate={editTemplate}
                                        item={item}
                                        index={index}
                                        handleQuestionForm={handleQuestionForm}
                                        handleQuestionAns={handleQuestionAns}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="screen-footer">
                            <div className="footer-nav-btn">
                              <div
                                className="btn-style"
                                contentEditable={editTemplate}
                                onBlur={(e) =>
                                  updateQuestion(e, index, "buttonText")
                                }
                              >
                                {item?.buttonText}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {editTemplate ? (
                  <div className="screen-layout border border-success">
                    <div className="d-flex justify-content-center align-items-center flex-column h-100">
                      <h3
                        className="cursor-pointer"
                        onClick={editTemplate ? addNewQuestionForm : null}
                      >
                        <i class="fa-solid fa-square-plus"></i> Question
                      </h3>
                    </div>
                  </div>
                ) : null}

                <ScreenLayout name={data?.name} notHeaderRequire="true">
                  <div className="screen-body px-35">
                    <div className="upload-img-blk">
                      <img
                        className="preview-img"
                        src={
                          selectedFile === null
                            ? finalForm?.image?.added_image == ""
                              ? ImagesUrl.shape_image
                              : `https://leadherself-api.csdevhub.com/src/public/form_images/${finalForm?.image?.added_image}`
                            : URL.createObjectURL(selectedFile)
                        }
                        alt="Image"
                      />
                      <div className="upload-img-file">
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          ref={fileInput}
                          onChange={({ target }) => handleFileInput(target)}
                        />
                        <label
                          htmlFor="ImageUpload"
                          onClick={
                            editTemplate ? handleSelectUploadImage : null
                          }
                        >
                          <i className="fa-solid fa-image" />
                        </label>
                      </div>
                    </div>
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "276px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            className="ls-font ft-22 lht-32"
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div
                      className="btn-text-style py-0"
                      contentEditable={editTemplate}
                      onBlur={(e) => {
                        setFinalForm({
                          ...finalForm,
                          returnHomeButtonText: e.target.innerText,
                        });
                      }}
                    >
                      {finalForm.returnHomeButtonText}
                    </div>
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setFinalForm({
                            ...finalForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {finalForm.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TempLayout>
  );
};

export default Scenario;
