// Navigation.tsx
import { useContext } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";
import { RoutePath } from "./routePath";
import { HashRouter as Router } from "react-router-dom";
import GenericPage from "../components/genericpage";
import PageNotFound from "../screens/pageNotFound";
function Navigation() {
  const { isAuthenticated } = useContext(AuthContext);

  const PublicRoute = () => {
    if (isAuthenticated === true) {
      return <Navigate to={RoutePath.USER_MANAGEMENT.path} replace />;
    }
    return <Outlet />;
  };

  const ProtectedRoute = () => {
    if (isAuthenticated === false) {
      return <Navigate to={RoutePath.LOGIN.path} replace />;
    }
    return (
      <GenericPage>
        <Outlet />
      </GenericPage>
    );
  };

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route index element={RoutePath.LOGIN.component} />
          <Route
            path={RoutePath.LOGIN.path}
            element={RoutePath.LOGIN.component}
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path={RoutePath.DASHBOARD.path}
            element={RoutePath.DASHBOARD.component}
          />
          <Route
            path={RoutePath.USER_MANAGEMENT.path}
            element={RoutePath.USER_MANAGEMENT.component}
          />
          <Route
            path={RoutePath.USER_DETAIL.path}
            element={RoutePath.USER_DETAIL.component}
          />
          <Route
            path={RoutePath.EDIT_USER_DETAIL.path}
            element={RoutePath.EDIT_USER_DETAIL.component}
          />
          <Route
            path={RoutePath.JOURNEY_MANAGEMENT.path}
            element={RoutePath.JOURNEY_MANAGEMENT.component}
          />
          <Route
            path={RoutePath.JOURNEY_DETAIL.path}
            element={RoutePath.JOURNEY_DETAIL.component}
          />
          <Route
            path={RoutePath.SKILLS_MANAGEMENT.path}
            element={RoutePath.SKILLS_MANAGEMENT.component}
          />
          <Route
            path={RoutePath.SKILL_DETAIL.path}
            element={RoutePath.SKILL_DETAIL.component}
          />
          <Route
            path={RoutePath.STEPS_DETAIL.path}
            element={RoutePath.STEPS_DETAIL.component}
          />
          <Route
            path={RoutePath.EXERCISE_DETAIL.path}
            element={RoutePath.EXERCISE_DETAIL.component}
          />
          <Route
            path={RoutePath.CONTENT_MANAGEMENT.path}
            element={RoutePath.CONTENT_MANAGEMENT.component}
          />
          <Route
            path={RoutePath.LEADERBOARD_MANAGEMENT.path}
            element={RoutePath.LEADERBOARD_MANAGEMENT.component}
          />
          <Route
            path={RoutePath.NOTIFICATION_MANAGEMENT.path}
            element={RoutePath.NOTIFICATION_MANAGEMENT.component}
          />
          <Route
            path={RoutePath.LIBRARY_MANAGEMENT.path}
            element={RoutePath.LIBRARY_MANAGEMENT.component}
          />
          {/* <Route
            path={RoutePath.ADMIN_MANAGEMENT.path}
            element={RoutePath.ADMIN_MANAGEMENT.component}
          /> */}
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}
export default Navigation;
